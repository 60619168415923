import { Controller } from "@hotwired/stimulus"
import I18n from "i18n"

export default class extends Controller {
  static targets = ["feedback", "feedbackContainer", "submit", "radio"]

  connect() {
    this.element.addEventListener("change", () => {
      const radiosChecked = this.radioTargets.filter(radio => radio['checked'])
      if (radiosChecked.length) {
        this.submitTarget.removeAttribute("disabled")
      }
    })
  }

  hideFeedbackForm() {
    this.feedbackContainerTarget.classList.add("hidden")
    this.feedbackTarget.value = ""
  }

  showFeedbackForm({ target }) {
    this.feedbackTarget.placeholder = target.dataset.surveyPlaceholder
    this.feedbackContainerTarget.classList.remove("hidden")
  }
}
