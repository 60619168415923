import "core-js/stable"
import "regenerator-runtime/runtime"

import "config"

import "controllers/member"
import "@hotwired/turbo-rails"

import "helpers/stream_actions"
import "helpers/turbo_frames"

import { registerCustomAlignElements } from "helpers/trix"
registerCustomAlignElements()
