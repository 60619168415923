import { Controller } from "@hotwired/stimulus"
import { camelize } from "humps"

const DEFAULT_EVENT = "change"

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("[data-display-if-target]").forEach((element) => {
      this._conditionalDisplay(element, "data-display-if-target", "data-display-if-value", true)
    })

    this.element.querySelectorAll("[data-display-unless-target]").forEach((element) => {
      this._conditionalDisplay(element, "data-display-unless-target", "data-display-unless-value", false)
    })
  }

  toggle(event) {
    const element = event.currentTarget
    const selector = element.dataset.displayIfTarget || element.dataset.displayUnlessTarget
    const target = this.element.querySelector(selector)

    if (target && target.hasAttribute("value")) {
      target.value = (target.value === "false")
      target.dispatchEvent(new Event("change"))
    }
  }

  _conditionalDisplay(conditionalElement, targetAttribute, valueAttribute, displayOnMatch) {
    const target = this.element.querySelector(conditionalElement.getAttribute(targetAttribute))
    const displayValue = conditionalElement.getAttribute(valueAttribute)
    const className = conditionalElement.getAttribute("data-display-if-class") || "hidden"
    const statement = displayOnMatch ? "if" : "unless"
    const event = this._event(conditionalElement, statement)

    if (!target) {
      console.warn(`Missing display target: ${conditionalElement.getAttribute(targetAttribute)}`)
      return
    }

    const update = () => {
      const checkable = target.type.match("checkbox|radio")

      if (checkable) {
        if (target.checked == displayOnMatch) {
          this._display(conditionalElement, className)
        } else {
          this._hide(conditionalElement, className)
        }
      } else {
        if ((target.value == displayValue) == displayOnMatch) {
          this._display(conditionalElement, className)
        } else {
          this._hide(conditionalElement, className)
        }
      }
    }

    if(target.type == "radio") {
      this.element.querySelectorAll(`[type=radio][name="${target.name}"]`).forEach((element) => {
        element.addEventListener(event, update)
      })
    } else {
      target.addEventListener(event, update)
    }
    update()
  }

  _event(element, statement) {
    const attribute = camelize(`display-${statement}-event`)
    const event = element.dataset[attribute]

    return event || DEFAULT_EVENT
  }

  _display(target, className) {
    target.classList.remove(className)
  }

  _hide(target, className) {
    target.classList.add(className)
  }
}
