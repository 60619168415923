import { StreamActions } from "@hotwired/turbo"

StreamActions.redirect = function () {
  Memberful.redirectTo(this.target)
}

StreamActions.visit = function () {
  Turbo.visit(this.target)
}

StreamActions.reload = function () {
  document.getElementById(this.target).dispatchEvent(new CustomEvent("turbo-stream:reload"))
}
