import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
application.debug = process.env.NODE_ENV === "development"

const context = require.context("controllers/member", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

application.register("apply-coupon", require("controllers/shared/apply_coupon_controller").default)
application.register("auto-hide", require("controllers/shared/auto_hide_controller").default)
application.register("cloudflare", require("controllers/shared/cloudflare_controller").default)
application.register("disable-with", require("controllers/shared/disable_with_controller").default)
application.register("display", require("controllers/shared/display_controller").default)
application.register("dropdown", require("controllers/shared/dropdown_controller").default)
application.register("number-input", require("controllers/shared/number_input_controller").default)
application.register("plan-change-form", require("controllers/shared/plan_change_form_controller").default)
application.register("scroll-into-view", require("controllers/shared/scroll_into_view_controller").default)
application.register("tooltip", require("controllers/shared/tooltip_controller").default)
