<template>
  <div id="gift-checkout">
    <CheckoutHeader v-if="currentStep != 'show_order'" :planPrice="subtotal" :planName="item.name" :price="total" />

    <div v-if="memberPriceBelowMinimum" class="notice-box mb-4">
      <p>{{ t(".amount_too_low") }}</p>
    </div>

    <div v-if="memberPriceAboveMaximum" class="notice-box mb-4">
      <p>{{ t(".amount_too_high") }}</p>
    </div>

    <div v-show="currentStep != 'show_order'" class="checkout">
      <form
        ref="form"
        :class="{ invalid: errorMessages.length }"
        novalidate
        @submit.prevent="submit">

        <div v-for="error in errorMessages" class="error-message">
          {{ error }}
        </div>

        <div v-show="currentStep == 'recipient_info'">
          <RecipientInformation v-on:update="setRecipientInformation" />
        </div>

        <div v-show="currentStep == 'payment_info'">
          <p class="memberful-form-note">
            <a href="#" v-on:click.prevent="showRecipientInfo">{{ t(".back_to_options") }}</a>
          </p>

          <template v-if="!memberLoggedIn">
            <div class="mb-3">
              <input v-model="giverName" class="input" v-bind:class="{ with_error: hasError('giverName') }" :placeholder="t('.your_name')" type="text" :aria-label="t('.your_name')" >
            </div>

            <EmailInput
              v-model="giverEmail"
              :placeholder="t('.your_email')"
              :unique="false"
              />
          </template>

          <template v-if="coupon">
            <div class="pb-2 text-sm text-green-500">
              <p>{{ t(".coupon_applied", { discount: formattedDiscount }) }}</p>
            </div>
          </template>
          <template v-else>
            <CouponBox v-if="showCouponInput" :planId="item.id" :price="subtotal"/>
          </template>

          <PaymentForm
            ref="paymentForm"
            action="/gifts"
            :buttonLabel="t('.place_order')"
            :formData="formData"
            :itemName="item.name"
            :requireBillingAddress="requireBillingAddress"
            :requireCard="requireCard"
            :savedCard="savedCard"
            :sitePolicies="sitePolicies"
            @success="success"
            @error="addError">
          </PaymentForm>
        </div>
      </form>
    </div>

    <div v-show="currentStep == 'show_order'">
      <OrderComplete :message="t('.order_complete')" :copy="t('.check_inbox')"></OrderComplete>
    </div>
  </div>
</template>

<script>
import CheckoutHeader from "./checkout_header.vue"
import EmailInput from "../components/EmailInput.vue"
import OrderComplete from './order_complete.vue'
import CouponBox from "./coupon_box.vue"
import PaymentForm from "../components/PaymentForm.vue"
import RecipientInformation from "./recipient_information.vue"

import { trackOrderInGoogleAnalytics } from "./helpers.js"
import { getMetaValue } from "helpers"
import { formatCurrency } from "helpers/currency"
import { EMAIL_REGEX } from "helpers/email"
import { mapGetters, mapState } from "vuex"

export default {
  components: {
    CheckoutHeader,
    EmailInput,
    OrderComplete,
    CouponBox,
    PaymentForm,
    RecipientInformation,
  },

  props: {
    backToSiteUrl: String,
    memberLoggedIn: Boolean,
    memberPriceAboveMaximum: Boolean,
    memberPriceBelowMinimum: Boolean,
    requireBillingAddress: Boolean,
    savedCard: Object,
    showCouponInput: Boolean,
    sitePolicies: Array
  },

  data: function () {
    return {
      currentStep: "recipient_info",
      errors: {
        base: []
      },
      giverEmail: "",
      giverName: "",
      orderNumber: {},
      recipientInformation: {}
    }
  },

  methods: {
    validate() {
      if(!this.memberLoggedIn) {
        if(!EMAIL_REGEX.test(this.giverEmail)) {
          this.errors.giverEmail = this.t(".invalid_email")
        }
        if(!this.giverName.trim()) {
          this.errors.giverName = this.t(".name_required")
        }
      }

      let valid = this.$refs.form.checkValidity()

      return this.errorMessages.length === 0 && valid
    },

    submit() {
      this.clearErrors()

      if (this.validate()) {
        this.$refs.paymentForm.submit()
      }
    },

    success(response) {
      this.orderNumber = response.order.number
      this.currentStep = "show_order"
      trackOrderInGoogleAnalytics({
        orderNumber: this.orderNumber,
        orderTotal: response.order.total,
        planName: this.item.name
      })
      setTimeout(() => {
        this.redirectAfterComplete()
      }, 3000)
    },

    addError(message) {
      this.errors.base.push(message)
    },

    showRecipientInfo() {
      this.clearErrors()
      this.currentStep = "recipient_info"
    },

    clearErrors() {
      this.errors = { base: [] }
    },

    hasError(element) {
      return element in this.errors
    },

    setRecipientInformation(recipientInformation) {
      this.recipientInformation = recipientInformation
      this.currentStep = "payment_info"
    },

    redirectAfterComplete() {
      const notIframe = Memberful.notInIframe()

      if (this.backToSiteUrl != null && notIframe) {
        window.location = this.backToSiteUrl
      }

      if (this.backToSiteUrl == null && notIframe) {
        window.history.back()
      } else {
        Memberful.RpcClient.call("close")
      }
    }
  },

  computed: {
    deliverOn() {
      const date = this.recipientInformation.deliveryDate

      if (date) {
        return [
          date.getFullYear(),
          date.getMonth()+1,
          date.getDate()
        ].join("-")
      }
    },

    errorMessages() {
      return Object.values(this.errors).reverse().flat()
    },

    formattedPrice() {
      return formatCurrency(this.total)
    },

    formattedDiscount() {
      if (this.couponDiscount) return formatCurrency(this.couponDiscount.amount)
    },

    requireCard() {
      return this.total != 0
    },

    formData() {
      return {
        deliver_on: this.deliverOn,
        giver_email: this.giverEmail.trim(),
        giver_name: this.giverName.trim(),
        message: this.recipientInformation.message,
        recipient_email: this.recipientInformation.recipientEmail,
        recipient_name: this.recipientInformation.recipientName
      }
    },

    ...mapState("checkout", [
      "coupon",
      "item"
    ]),

    ...mapGetters("checkout", [
      "couponDiscount",
      "subtotal",
      "total"
      ])
  }
}
</script>
